import React from 'react';
import { Editor, EditorState, EditorBlock } from "draft-js";
import 'draft-js/dist/Draft.css';
import './StanzaEditor.css'
import { Container, Row, Col } from 'react-bootstrap';

function Verse(props) {
    return (
        <div className='editor-line' data-syllable-count={props.blockProps.syllableCount}>
            <div className='editor-line-text'>
                <EditorBlock {...props} />
            </div>
        </div>
    );
}

const blockRendererFn = (block) => {
    return {
        component: Verse,
        props: {
            syllableCount: block.getData().get('syllableCount'),
        },
    }
};

export default function StanzaEditor() {
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
    const [title, setTitle] = React.useState('');
    const editor = React.useRef(null);
    function focusEditor() {
        editor.current.focus();
    }

    const updateStateWithSyllableCount = (state) => {
        let currentContent = state.getCurrentContent();
        let blockMap = currentContent.getBlockMap();

        const blocks = currentContent.getBlocksAsArray();
        for (let i = 0; i < blocks.length; i++) {
            const block = blocks[i];
            let syllableCount;
            if (block.getText() === "") {
                syllableCount = null;
            } else {
                syllableCount = (block.getText().match(/а|о|и|е|ё|э|ы|у|ю|я/g) || []).length;
            }
            const updatedBlock = block.mergeIn(['data'], { 'syllableCount': syllableCount });
            blockMap = blockMap.merge({ [block.getKey()]: updatedBlock })
        }
        currentContent = currentContent.merge({ blockMap });

        setEditorState(EditorState.push(state, currentContent, 'metadata-update'));
    }

    React.useEffect(() => {
        focusEditor();
    }, []);

    return (
        <Container fluid="md">
            <Row>
                <Col>
                    <input type="text" className="h1 poem-title mt-3 mb-5" placeholder="Стих без названия" value={title} onChange={(e) => setTitle(e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col style={{ minHeight: '100vh' }} onClick={focusEditor}>
                    <Editor
                        ref={editor}
                        editorState={editorState}
                        onChange={updateStateWithSyllableCount}
                        blockRendererFn={blockRendererFn}
                        placeholder="Начните писать стихотворение..."
                    />
                </Col>
            </Row>
        </Container>
    );
}